<template>
    <section class="wh-100 vert-scroll" style="padding: 0 0 9px 0;">
        <div class="spacer-label text-highlight">
            {{ $t('TITLE.LIVE') }}
        </div>
        <div v-if="!live || !live.length" class="no-live-notice">
            <el-empty :image-size="100" :description="$t('MESSAGE.NO-DATA')"></el-empty>
        </div>
        <el-timeline style="padding-left: 10px;">
            <el-timeline-item
                class="mobile-portal-timeline"
                v-for="x in live"
                :key="x.id"
                :timestamp="x.event_time"
                placement="top"
            >
                <a style="padding: 0 30px 0 0;display: block" :href="$replaceUrlOrigin(x.url)">
                    <div class="card-image">
                        <div class="picture" v-lazy:background-image="{src:x.picture}"></div>
                        <div class="description">
                            <span class="section" style="color: lightgray">{{ x.category }}</span>
                            <div class="title">{{ x.subject }}</div>
                        </div>
                    </div>
                </a>
            </el-timeline-item>
        </el-timeline>
    </section>
</template>
<script>
import PortalMixin from "@/views/mixins/mobile-portail-mixin";

export default {
    name: "mobile-live",
    mixins: [PortalMixin],
    mounted() {
        this.loadEvents();
    },
}
</script>
<style lang="scss">
.mobile-portal-timeline {
    .el-timeline-item__tail {
        border-left-color: $--public-highlight;
        opacity: 0.5;
    }

    .el-timeline-item__node {
        background-color: $--public-highlight;
    }

    .el-timeline-item__timestamp {
        color: #333;
        font-weight: bold;
    }

    .card-image {
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        position: relative;

        .picture {
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: cover;
            border-radius: 5px 5px 0 0;
        }

        .description {
            position: absolute;
            background-color: RGBA(0, 0, 0, 0.6);
            bottom: 0;
            width: 100%;
            color: white;
            padding: 0 5px;
            border-radius: 0 0 5px 5px;

            .section {
                font-size: 12px;
            }
            .title {
                color:$--public-highlight;
            }
        }
    }
}
</style>
