<template>
    <section class="wh-100 vert-scroll" style="padding: 9px 0;">
        <div class="mobile-portal" style="padding: 0 5px;">
            <nut-swiper
                direction="horizontal"
                :auto-play="10000"
                :loop="true"
                :canDragging="true"
                :swiperData="show_reels"
                v-if="show_reels"
                :style="{height:show_reel_height + 'px'}"
            >
                <div class="nut-swiper-slide portal-slide position-relative" v-for="item in show_reels" :key="item.id"
                     :style="{backgroundImage:'url(' + item.picture + ')'}"
                >
                    <div class="description">
                        <div class="wrap">
                            <div class="time" style="font-size: 12px;">{{ item.live ? "直播" : "" }} {{ item.event_time }}
                                <span class="float-right" style="color: lightgray">{{ item.memo }}</span>
                            </div>
                            <div class="title">{{ item.subject }}</div>
                        </div>
                    </div>
                </div>
            </nut-swiper>
        </div>
        <div class="spacer-label text-highlight">
            {{$t('TITLE.LIVE')}}
        </div>
        <div v-if="live && live.length" class="event-card-list">
            <a v-for="x in live" :key="x.id" :href="x.url">
                <div class="card-image" v-lazy:background-image="{src:x.picture}"></div>
                <div class="card-description">
                    <div class="subject"> {{ x.subject }}</div>
                    <div class="time">
                        <div>{{ x.event_time | str16 }}</div>
                    </div>
                </div>
            </a>
        </div>
        <div v-else class="no-live-notice">
            <el-empty :image-size="100" :description="$t('MESSAGE.NO-DATA')"></el-empty>
        </div>
        <div class="spacer-label text-highlight">
            {{$t('TITLE.PLAYBACK')}}
        </div>
        <div v-if="playback && playback.length" class="event-card-list">
            <a v-for="x in playback" :key="x.id" :href="x.url">
                <div class="card-image" v-lazy:background-image="{src:x.picture}"></div>
                <div class="card-description">
                    <div class="subject text-black"> {{ x.subject }}</div>
                    <div class="time d-flex justify-content-between">
                        <div>{{ x.event_time | str16 }}</div>
                        <div><i class="el-icon-view"></i> {{ x.watched | number }}</div>
                    </div>
                </div>
            </a>
        </div>
        <div v-else class="no-live-notice ">
            <el-empty :image-size="100" :description="$t('MESSAGE.NO-DATA')"></el-empty>
        </div>
    </section>
</template>
<script>


import PortalMixin from "@/views/mixins/mobile-portail-mixin";

export default {
    name: "mobile-portal",
    mixins: [PortalMixin],
    mounted() {
        this.loadShowReels();
        this.loadEvents();
        window.addEventListener('resize', this.handleResize, false);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false)
    },
}
</script>


