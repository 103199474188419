<template>
    <div class="wh-100 d-flex flex-column">
        <div class="spacer-label text-highlight d-flex flex-space-between">
            <div>{{ $t('TITLE.PLAYBACK') }}</div>
            <div class="mr-3 spacer-label-filter" @click="handleFilterClick" :class="{'filtered':filtered}">
                <i :class="filtered?'el-icon-s-opportunity':''"></i>
                {{ $t('TITLE.FILTER') }} <i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="vert-scroll" style="flex:1; padding: 0 5px;">
            <nut-infiniteloading class="event-card-list"
                                 style="padding: 0;"
                                 @loadmore="onInfinite"
                                 is-show-mod
                                 :has-more="isHasMore"
                                 :is-loading="isLoading"
                                 loading-txt="Loading..."
                                 :use-window = "false"
                                 :threshold="10">
                <a v-for="x in playback" :key="x.id" :href="$replaceUrlOrigin(x.url)">
                    <div class="card-image" v-lazy:background-image="{src:x.picture}"></div>
                    <div class="card-description">
                        <div class="subject text-black"> {{ x.subject }}</div>
                        <div class="time d-flex justify-content-between">
                            <div>{{ x.event_time | str16 }}</div>
                            <div><i class="el-icon-view"></i> {{ x.watched | number }}</div>
                        </div>
                    </div>
                </a>
                <div slot="loading" class="w-100 text-center " >Loading ...</div>
                <div slot="unloadMore" class="w-100 text-center " v-show="playback && playback.length">{{$t('TITLE.NO-MORE')}}</div>
            </nut-infiniteloading>
            <div v-if="!playback || !playback.length" class="no-live-notice ">
                <el-empty :image-size="100" :description="$t('MESSAGE.NO-DATA')"></el-empty>
            </div>
        </div>
        <nut-popup closeable get-container="body"
                   destroy-on-close
                   position="right" :style="{ width:'300px', height: '100%' }"
                   v-model="show_filter_panel"
                   @open="handlePopupOpen" @close="handlePopupClose"
                   close-icon-position="top-right">
            <div class="filter_wrap">
                <div class="title">{{ $t('TITLE.SELECT-FILTER') }}</div>
                <div class="body">
                    <nut-cell :show-icon="true" :desc="labelOf(search.filters)" :title="$t('TITLE.SELECT-SECTION-DATE')"
                              @click.native="handlePickerDate"></nut-cell>
                    <a class="nut-cell bg-white">
                        <div class="nut-cell-box">
                            <nut-textinput
                                v-model="search.speaker"
                                :label="$t('TITLE.SPEAKER')"
                                :clearBtn="true"
                                :disabled="false"
                            />
                        </div>
                    </a>
                    <a class="nut-cell bg-white">
                        <div class="nut-cell-box">
                            <nut-textinput
                                v-model="search.search"
                                :label="$t('TITLE.SUBJECT')"
                                :clearBtn="true"
                                :disabled="false"
                            />
                        </div>
                    </a>
                    <nut-button block class="mt-3" @click="handleSearchClick">
                        {{ $t('TITLE.SEARCH') }}
                    </nut-button>
                </div>
            </div>

            <nut-picker
                :is-visible.sync="show_date_range_picker"
                :list-data="options"
                :default-value-data="search.filters"
                @confirm="handle_data_range_picker_confirm"
            >
            </nut-picker>
        </nut-popup>
    </div>
</template>
<script>
import PortalMixin from "@/views/mixins/mobile-portail-mixin";

export default {
    name: "mobile-playback",
    mixins: [PortalMixin],
    data() {
        return {
            show_section_picker: false,
            show_date_range_picker: false,
            options: [[],
                [
                    {label: '所有时间', english: 'All Events', value: 0},
                    {label: '半年', english: 'Half Year', value: 1},
                    {label: '一年', english: 'One Year', value: 2},
                    {label: '三年', english: 'Three Years', value: 3}
                ]
            ],
            show_filter_panel: false,
            search: {
                filters: [0, 0],
                speaker: '',
                search: '',
            },
            //for infiniteLoading
            isHasMore: true,
            isLoading: false,
            isErr: false,
        }
    },
    mounted() {
        this.loadEvents();
    },
    computed: {
        filtered() {
            return this.search.filters[0] !== 0 || this.search.filters[1] !== 0 || this.search.speaker || this.filter;
        }
    },
    methods: {
        onInfinite() {
            this.loadEvents(true);
        },
        handleSearchClick() {
            this.show_filter_panel = false;
            this.handlePopupClose();
            this.loadEvents();
        },
        handle_data_range_picker_confirm(value) {
            this.show_date_range_picker = false;
            this.search.filters = value;
        },
        handlePopupOpen() {
            this.search.search = this.process.filter;
        },
        handlePopupClose() {
            this.setFilter(this.search.search)
        },
        handlePickerDate() {
            this.show_date_range_picker = true
        },
        handleFilterClick() {
            this.show_filter_panel = true;
        },
        loadEvents(skip) {
            const vm = this;
            this.isLoading = true;

            const filter = {filter: this.filter, section: this.search.filters[1], speaker: this.search.speaker};
            const today = new Date();
            const end_date = new Date();
            if (this.search.filters[0] === 1) { //半年
                end_date.setMonth(end_date.getMonth() - 6);
                filter.dates = [today.Format('YYYY-MM-DD'), end_date.Format('YYYY-MM-DD')];
            } else if (this.search.filters[0] === 2) {
                end_date.setUTCFullYear(end_date.getFullYear() - 1);
                filter.dates = [today.Format('YYYY-MM-DD'), end_date.Format('YYYY-MM-DD')];
            } else if (this.search.filters[0] === 3) {
                end_date.setUTCFullYear(end_date.getFullYear() - 3);
                filter.dates = [today.Format('YYYY-MM-DD'), end_date.Format('YYYY-MM-DD')];
            }
            if (skip) {
                filter.skip = this.playback.length;
            } else {
                this.isHasMore = true;
            }

            this.$api("Admin.Portal.Playback", filter, true).then(res => {
                const data = res.data.data;
                if (!data['result'] || !data['result'].length) {
                    vm.isHasMore = false;
                }
                if (skip && vm.playback && vm.playback.length) {
                    data['result'].forEach(item=>{
                        if (!vm.playback.valueSearch(item.id,'id',true)) {
                            vm.playback.push(item);
                        }
                    });
                } else
                    this.playback = data['result'];
                if (!this.options[0] || !this.options[0].length) {
                    this.options[0] = [{english: "All Sections", label: "全部栏目", value: 0}];
                    data['sections'].forEach((item) => {
                        vm.options[0].push({english: item.english, label: item.name, value: item.id})
                    });
                }
                this.isLoading = false;
            }).catch(function () {
                vm.isLoading = false;
            });
        },
        labelOf(arr) {
            const vm = this;
            const ret = arr.map((value, idx) => {
                const stacks = vm.options[idx];
                const one = stacks.valueSearch(value);
                return one ? (one.constructor === Object ? (this.$i18n.locale === 'en' ? (one.english || one.label) : one.label) : one) : one;
            });
            return ret.join(', ');
        }
    }
}
</script>
<style lang="scss">
    .spacer-label {
        .spacer-label-filter {
            font-weight: normal;
            font-size: 85%;
            color: #999999;

            &.filtered {
                font-weight: bold;
                color: $--public-highlight;
            }
        }
    }

    .nut-infiniteloading.event-card-list {
        .load-more {
            width: 100%;
            .bottom-tips {
                color: #999999;
            }
        }
    }

    .filter_wrap {
        height: 100%;
        width: 100%;
        background-color: #F0F0F0;

        .title {
            height: 48px;
            line-height: 28px;
            padding: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
        }

        .body {
            height: calc(100% - 48px);
            overflow-x: hidden;
            overflow-y: auto;
            font-size: 14px;
            color: #333333;
        }

        .nut-textinput {
            flex: 1;

            .nut-textinput-label {
                width: 80px;
            }
        }

        a:hover {
            text-decoration: none !important;
        }

    }

</style>
